<template>  
    

    <div class="login-form-wrap">  
        <div class="login-item">
            <h2>
                Вход в систему
            </h2>
        </div>
        <div class="login-item">          
            <span class="p-float-label">
                <InputText id="login" :class="{'p-invalid':v$.login.$invalid && isSubmittedForm}" type="text" v-model="login" />
                <label for="login">Логин</label>
            </span>
        </div>
        <div class="login-item">            
            <span class="p-float-label">
                <Password id="password" :class="{'p-invalid':v$.password.$invalid && isSubmittedForm}" type="text" v-model="password" />
                <label for="password">Пароль</label>
            </span> 
        </div>
        <div class="login-item">            
            <small class="p-error">{{errorText}}</small>
        </div>
       

        <Button label="Вход" @click='loginButtonOnClick'/>


    </div>

</template>
  
  <script>

  import { useVuelidate } from "@vuelidate/core";
  import {required } from "@vuelidate/validators";
  import Password from 'primevue/password';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';

  import UserService from '../service/UserService';
  
  export default {
    name: 'LoginPage',
    setup: () => ({ v$: useVuelidate() }),
    components: {    
        Password,
        InputText,
        Button
    }, 
    props: [
    
    ],
    data() {
      return {
        login:null,
        password:null,
        isSubmittedForm: false,
        errorText:'',
      }
    },
    validations() {
          return {
            login:{ required }, 
            password:{ required }, 
          }
      },
    created() {      
      this.userService = new UserService();
    },
    
    methods:{
        loginButtonOnClick(){
            this.isSubmittedForm=true;
            this.errorText='';
            if(!this.v$.login.$invalid && !this.v$.password.$invalid){                
                this.userService.login(this.login,this.password).then(this.loginHandler);
            }

        },
        loginHandler(data){           
            if(data.status=='error'){
                        this.errorText=data.info;
            }
            if(data.status=='success'){
                this.$emit('on-user-login',data.data);
            }
        }
      
    },
  }
  </script>
  
  
<style>

.login-form-wrap{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 330px;
    height: 170px;
}

.login-item {
    margin-bottom: 30px;
}
</style>