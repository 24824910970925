<template>   
      <div> 
        <h5>
          Название
        </h5>
        <InputText :class="{'p-invalid':v$.newProductModel.name.$invalid}" v-model="newProductModel.name"  type="text"  />        
      </div> 

      <div> 
        <h5>
          Единица измерения
        </h5>
        <InputText :class="{'p-invalid':v$.newProductModel.unit.$invalid}" v-model="newProductModel.unit"  type="text"  />        
      </div> 

      <div>  
        <h5>
          Дата добавления номенклатуры
        </h5>       
        <Calendar :class="{'p-invalid':v$.newProductModel.date_create.$invalid}" v-model="newProductModel.date_create" dateFormat="dd.mm.yy"  />     
      </div> 

       <!--<div>   
        <h5>
         Контрагент
        </h5>
      
        <KontragentSelector 
        @change="OnChangeKontragent" 
        @on-kontragent-update="OnKontragentUpdate" 
        :kontragentList='kontragentList'/>
      </div> 

      <div>
        <h5>
          Цена
        </h5>        
        <DataTable :value="warehouseProductMap" :scrollable="true" scrollHeight="200px">
          <Column header="Склад" field="name"> 
          </Column>
          
          <Column header="Цена">
            <template #body="slotProps">  
              <InputNumber  :minFractionDigits="2" :maxFractionDigits="5" mode="decimal"  v-model="slotProps.data.price" :class="{'p-invalid':(slotProps.data.price==0 || slotProps.data.price<0)}"/> 
            </template>
          </Column>
        </DataTable>
      </div>-->

    
      <div class="dd-bottom-button-wrap">    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <Button @click="saveNewProduct" label="Сохранить поступление" class="mt-2" icon="pi pi-check"  />
      </div>
</template>

<script>

import Button from 'primevue/button';


import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';

import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";

import ProductService from '../service/ProductService';
import KontragentService from '../service/KontragentService';
import BusinesEvent from '../service/BusinesEvent';
import WarehouseService from '../service/WarehouseService';

export default {
  name: 'NewPropuctPopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    InputText,
    Calendar,
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {    
      warehouseProductMap:null,
      selected_warehouse:null,
      newProductModel:{
        name:null,
        unit:null,
        price:null,
     //   quantity:null,
        date_create:null,
      //  kontragent_id:null,
      },
      kontragentList:null,  
    }
  },
  validations() {
        return {
          newProductModel:{
            name:{ required },      
            unit:{ required },  
            date_create:{ required }, 
          },
        }
    },
  created() {
      this.productService = new ProductService();
      this.kontragentService = new KontragentService();
      this.businesEvent = new BusinesEvent();
      this.warehouseEvent = new WarehouseService();
      
  },
  mounted() {

    this.selected_warehouse = this.dialogRef.data.selected_warehouse;      

    this.kontragentService.getList().then(data => this.kontragentList = data);

    var self=this;
    this.warehouseEvent.getList().then(function(data){
      var warehouseList = data;
      self.warehouseProductMap = [];
     for (const warehouse of warehouseList) {       
      self.warehouseProductMap.push({
        'name':warehouse.name,
        'id':warehouse.id,
        'price':0,        
       });
     }
    });
    

  },
  methods:{    

    OnChangeAddedProduct(e){
      e.unit=e.selectedProduct.unit;
    },   
   

    OnKontragentUpdate(){
      this.kontragentService.getList().then(data => this.kontragentList = data);
    },
   
    closePopup(){
      this.dialogRef.close();
    },
    
    OnChangeKontragent(e){

        this.addedProductModel.kontragent_id=e.id
    },
    updateKontragentList(response) {     
      this.kontragentList=response.data; 
    },

   
    

  

  

   
    productAdded(){
      
        this.dialogRef.close({
          'cmd':'newProduct'
        });
        
         
    },
    saveNewProduct(){
     

     /* 
     
     var isPriceListEmpty=false;
      for (const item of this.warehouseProductMap) {
          if(item.price==0 || item.price<0 || item.price==null){
            isPriceListEmpty=true;
            break;
          }
      }*/    

      if( this.v$.$invalid==false /*&& isPriceListEmpty==false*/){

        this.productService.createProduct({
          product:this.newProductModel,
          warehouse_map:this.warehouseProductMap
        }).then(this.productAdded);   

      }

 
    },
    
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
</style>