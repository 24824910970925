import axios from 'axios';
export default class ReportService {

   

	getByDates(warehouse,dates) {
      
        return axios
                    .get(window.$backend_host+'/report/by-period',{
                        params:{
                        'warehouse_id':warehouse.id,
                        'dates':dates
                        }              
                    }).then(d => d.data);            
    }

    
}