<template>  
  <div> 
    <h5>
      Дата продажи
    </h5>       
    <Calendar :class="{'p-invalid':v$.addedProductModel.date_create.$invalid}" v-model="addedProductModel.date_create" dateFormat="dd.mm.yy"  />     
  </div> 

  <div>   
    <h5>
     Контрагент
    </h5>
   
    <KontragentSelector @change="OnChangeKontragent" @on-kontragent-update="OnKontragentUpdate" :kontragentList='kontragentList'/>
  </div> 

  <div>

    <h5>
     Товары
    </h5> 
    
    
    <Button @click="addTemplateProduct" label="Добавить товар для продажи" icon="pi pi-plus" iconPos="left" /> 

    <br><br>         
    <DataTable  :value="addedProductList" :resizableColumns="true" columnResizeMode="fit" class="product-list" scrollHeight="200px">
      <Column header="Товар"  class="product-list-cell-name">           
        <template #body="slotProps">              
          <Dropdown  
          @change="OnChangeAddedProduct(slotProps.data)"
        v-model="slotProps.data.selectedProduct" 
        :optionValue="id"      
        :options="defaultProductList" 
        optionLabel="name" 
        placeholder="Выберите товар" 
        :filter="true"
        filterPlaceholder="Поиск"
  :class="{'p-invalid':slotProps.data.selectedProduct==null}"
        />
        </template> 
      </Column> 
      <Column header="Количество" :class="quanaity-row" class="product-list-cell-quantity">
        <template #body="slotProps" >        
          <InputNumber :min="0" highlightOnFocus='true' @input="onChangeProductList($event,slotProps,'quantity')"  mode="decimal" v-model="slotProps.data.quantity" :class="{'p-invalid':(slotProps.data.quantity==0 || slotProps.data.quantity<0)}"/>
        </template>
      </Column>
      <Column header="Цена продажи" class="product-list-cell-price">
        <template #body="slotProps">           
          <InputNumber :min="0" highlightOnFocus='true' @input="onChangeProductList($event,slotProps,'price')"  :minFractionDigits="2" :maxFractionDigits="5" mode="decimal" v-model="slotProps.data.price" :class="{'p-invalid':(slotProps.data.price==0 || slotProps.data.price<0)}"/> 
        </template>
      </Column>
      <Column header="Сумма позиции"  class="product-list-cell-summ">
          <template #body="slotProps">
            {{slotProps.data.quantity*slotProps.data.price}} руб.              
          </template>
      </Column>
    </DataTable>
      <div class="total-wrap">
          <div  class="total-label">
            Сумма товаров: {{totalProductSumm}} руб.   
          </div>
        </div>
  </div>
  
  <div class="dd-bottom-button-wrap">    
    <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
    <Button @click="saveAddedProduct" label="Продать товары" class="mt-2" icon="pi pi-check"  />
  </div>
</template>


<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import KontragentSelector from './KontragentSelector.vue';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";

import ProductService from '../service/ProductService';
import KontragentService from '../service/KontragentService';
import BusinesEvent from '../service/BusinesEvent';

export default {
name: 'SaleProductPopup',
setup: () => ({ v$: useVuelidate() }),
components: {  
Button,
Dropdown,
Calendar,
KontragentSelector,
DataTable,
Column,
InputNumber,
}, 
inject: ['dialogRef'],
props:[

],
data() {
return {    
  addedProductList:[],
  headerProductList:null,
  
  selected_warehouse:null,
  defaultProductList:null,
  addedProductModel:{
                date_create:null,
                kontragent_id:null,
              },
  kontragentList:null,  
  totalProductSumm:0,
} 
},

validations() {
    return {
      addedProductModel:{
        date_create:{ required },      
             
      },
    }
},
created() {
  this.productService = new ProductService();
  this.kontragentService = new KontragentService();
  this.businesEvent = new BusinesEvent();
},
mounted() {

    this.selected_warehouse = this.dialogRef.data.selected_warehouse;      

    this.productService.getList(this.selected_warehouse.id).then(data => this.defaultProductList = data.list);
    this.kontragentService.getList().then(data => this.kontragentList = data);
    this.addTemplateProduct();

},
methods:{ 
  onChangeProductList(e,slotProps,fieldName){
   
    this.addedProductList[slotProps.index][fieldName]=e.value;

    this.totalProductSumm=0;
    if(this.addedProductList!=null && this.addedProductList.length>0){
      for (const key in this.addedProductList) {
        this.totalProductSumm+=this.addedProductList[key].price*this.addedProductList[key].quantity;
      } 
    }
  },

OnChangeAddedProduct(e){
  e.unit=e.selectedProduct.unit;
},   
addTemplateProduct(){

  this.addedProductList.push({
    selectedProduct:null,
    quantity:0,
    unit:null,
    price:0,
  });
},

OnKontragentUpdate(){
  this.kontragentService.getList().then(data => this.kontragentList = data);
},

closePopup(){
  this.dialogRef.close();
},

OnChangeKontragent(e){

    this.addedProductModel.kontragent_id=e.id
},
updateKontragentList(response) {     
  this.kontragentList=response.data; 
},









productAdded(response){


 
    if(response.data['error']!=undefined){
      
      for (const key in response.data['error']) {
        this.$root.$toast.add({severity:'error', summary: response.data['error'][key]['name'], detail:response.data['error'][key]['info'], life: 20000});  
      }
      
    }else{
      this.dialogRef.close({
      'cmd':'saleProduct'
    });
    }
  
  
    
     
},
saveAddedProduct(){
 


  var productList={};
  for (const key in this.addedProductList) {
    var selectedProduct=this.addedProductList[key].selectedProduct;
    var quantity=this.addedProductList[key].quantity;
    var price=this.addedProductList[key].price;

    if(selectedProduct!=null && quantity>0){
      productList[selectedProduct.id]={
        quantity:quantity,
        price:price
      };
    }
  }

  

  if(Object.keys(productList).length>0 && this.v$.$invalid==false){

    

    this.businesEvent.productsSaleKontragent({
      'data':{
        'date_create': this.addedProductModel.date_create,
        'kontragent_id': this.addedProductModel.kontragent_id,
        'warehouse_id':this.selected_warehouse.id,
      },
      'product_list':productList
    }).then(this.productAdded);
    

    
  }


},


},
}
</script>



<style>  
.dd-bottom-button-wrap{
margin-top: 20px;
text-align: right;
}
.total-label{
  display: inline-block;
}
.total-wrap{
  margin-top: 10px;
  text-align: right;
}

.product-list th.product-list-cell-name,
.product-list td.product-list-cell-name{
  width: 55%;
}
.product-list th.product-list-cell-quantity,
.product-list td.product-list-cell-quantity{
  width: 15%;
}
.product-list th.product-list-cell-price,
.product-list td.product-list-cell-price{
  width: 15%;
}
.product-list th.product-list-cell-summ,
.product-list td.product-list-cell-summ{
  width: 15%;
}



.product-list .p-inputtext,.product-list .p-dropdown{
  width: 100%;
}
</style>