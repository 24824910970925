<template>  
  <Button label="Добавить нового контрагента" icon="pi pi-plus-circle" iconPos="left" @click="showPopup($event)" />  
  <Dialog @update:visible="hideHandler" header="Создание нового контрагента" :visible="is_show_window" :modal="true">

    <div> 
        <h5>
          Ф.И.О
        </h5>
        <InputText v-model="kontragent.surename"  type="text"  placeholder="Фамилия" /> 
        <InputText v-model="kontragent.firstname"  type="text"  placeholder="Имя" />  
        <InputText v-model="kontragent.patronimic"  type="text"  placeholder="Отчество" /> 
      </div> 

      <div> 
        <h5>
         Email
        </h5>
        <InputText v-model="kontragent.email"  type="text"  placeholder="Email" /> 
      </div> 

      <div> 
        <h5>
          Телефон
        </h5>
        <InputText v-model="kontragent.phone"  type="text"  placeholder="Телефон" /> 
      </div> 

      <div> 
        <h5>
          Адрес
        </h5>
        <InputText v-model="kontragent.address"  type="text"  placeholder="Адрес" /> 
      </div> 

      <div> 
        <h5>
          Индекс
        </h5>
        <InputText v-model="kontragent.zip"  type="text"  placeholder="Индекс" /> 
      </div> 

      <div> 
        <h5>
         ИНН
        </h5>
        <InputText v-model="kontragent.inn"  type="text"  placeholder="ИНН" /> 
      </div> 

      <div> 
        <h5>
         КПП
        </h5>
        <InputText v-model="kontragent.kpp"  type="text"  placeholder="КПП" /> 
      </div> 

      <div> 
        <h5>
         Расчётный счёт
        </h5>
        <InputText v-model="kontragent.rs"  type="text"  placeholder="Расчётный счёт" /> 
      </div> 

      <div> 
        <h5>
        ОГРН
        </h5>
        <InputText v-model="kontragent.ogrn"  type="text"  placeholder="ОГРН" /> 
      </div> 

      <div> 
        <h5>
        БИК
        </h5>
        <InputText v-model="kontragent.bik"  type="text"  placeholder="БИК" /> 
      </div> 

      <div>   
        <h5>
          Описание
        </h5>
        <Textarea  v-model="kontragent.description"   rows="5" cols="50" />
      </div> 
      <template #footer>    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <Button @click="saveKontragent" label="Добавить" class="mt-2" icon="pi pi-check"  />
      </template>
    
  </Dialog>
</template>

<script>
import axios from 'axios';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';


export default {
  name: 'KontragentPopup',
  components: {  
    Button,
    Dialog,
    InputText,
    Textarea
  }, 
 

  data() {
    return {  
      is_show_window:false,    
      kontragent:{
        firstname:null,
        surename:null,
        patronimic:null,
        email:null,
        address:null,
        zip:null,
        phone:null,      
        inn:null,
        kpp:null,
        rs:null,
        ogrn:null,
        bik:null,
        description:null
      }
    }
  },
  beforeMount(){
   
  },
  methods:{    
    showPopup(){
      this.is_show_window=true;
    },
    closePopup(){
      this.is_show_window=false;
    },
    
    kontragentCreated(){
     
      //произошло изменение списка контрагентов
      this.$emit('on-kontragent-update');
    },

    saveKontragent() {
     

      axios({
          method: 'post',
          url: window.$backend_host+'/kontragent/new',
          data: {
                'kontragent':this.kontragent
          } ,
          headers: {
            "Content-type": "application/x-www-form-urlencoded"
          }
        })
        .then(this.kontragentCreated);


      this.is_show_window=false;
      this.kontragent={
        firstname:null,
        surename:null,
        patronimic:null,
        email:null,
        address:null,
        zip:null,
        phone:null,      
        inn:null,
        kpp:null,
        rs:null,
        ogrn:null,
        bik:null,
        description:null
      };
            
    },
    hideHandler(value) { 
      if (!value) { this.is_show_window = false; } 
    }
  },
}
</script>


