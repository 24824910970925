import axios from 'axios';
export default class BusinesEvent {

    getList() {
		
        return axios
                    .get(window.$backend_host+'/busines-event/list',{
                        params:{                       
                        }              
                    }).then(d => d.data);        
    }
    productsSaleKontragent(params){

       
        return axios({
                        method: 'post',
                        url: window.$backend_host+'/busines-event/products-sale-kontragent',
                        data: {
                            'data':params.data,
                            'product_list':params.product_list,                           
                        } ,
                        headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                        }
                    }); 
    }
    kontragentAddedProducts(params){

      
        return axios({
                        method: 'post',
                        url: window.$backend_host+'/busines-event/kontragent-added-products',
                        data: {
                            'data':params.data,
                            'product_list':params.product_list,                           
                        } ,
                        headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                        }
                    }); 
    }
    getViewData(eventId) {
		
        return axios
                    .get(window.$backend_host+'/busines-event/get-view-data',{
                        params:{  
                            event_id:eventId                     
                        }              
                    }).then(d => d.data);        
    }

    
    
}