<template>  
  <Button label="Создать склад" icon="pi pi-plus-circle" iconPos="left" @click="showPopup($event)" />  
  <Dialog @update:visible="hideHandler" header="Создание нового склада" :visible="is_show_window" :modal="true">

      <div> 
        <h5>
          Название
        </h5>
        <InputText  :class="{'p-invalid':v$.warehouse_name.$invalid}" v-model="warehouse_name"  type="text"  />        
      </div> 

      <div>   
        <h5>
          Описание
        </h5>
        <Textarea  v-model="warehouse_description"   rows="5" cols="50" />
      </div> 
      <template #footer>    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <Button @click="saveWarehouse" label="Добавить" class="mt-2" icon="pi pi-check"  />
      </template>
    
  </Dialog>
</template>

<script>
import axios from 'axios';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";


export default {
  name: 'WarehousePopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    Dialog,
    InputText,
    Textarea
  }, 
  props: ["is_show_popup"],
  validations() {
        return {
            warehouse_name: {
                required
            },        
        }
    },
  data() {
    return {    
      warehouse_name:'',   
      warehouse_description:'',   
      is_show_window:false,    
    }
  },
  beforeMount(){
   
  },
  methods:{    
    showPopup(){
      this.is_show_window=true;
    },
    closePopup(){
      this.is_show_window=false;
    },
    
    warehouseCreated(){
     
      //произошло изменение складов, отправляю событие родителю
      this.$emit('on-warehouse-update');
    },

    saveWarehouse() {
     
      if(this.v$.$invalid==false){
          axios({
              method: 'post',
              url: window.$backend_host+'/warehouse/new',
              data: {
                    'warehouse':{
                      'name':this.warehouse_name,
                      'description':this.warehouse_description,
                    } 
              } ,
              headers: {
                "Content-type": "application/x-www-form-urlencoded"
              }
            })
            .then(this.warehouseCreated);


          this.is_show_window=false;
          this.warehouse_name='';  
          this.warehouse_description='';   
      }
    },
    hideHandler(value) { 
      if (!value) { this.is_show_window = false; } 
    }
  },
}
</script>


