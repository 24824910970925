<template>  
      <div> 
        <h5>
          Дата поступления
        </h5>       
        <Calendar  :class="{'p-invalid':v$.addedProductModel.date_create.$invalid}" v-model="addedProductModel.date_create" dateFormat="dd.mm.yy"  />     
      </div> 

      <div>   
        <h5>
         Контрагент
        </h5>
       
        <KontragentSelector 
        @change="OnChangeKontragent" 
        @on-kontragent-update="OnKontragentUpdate" 
        :kontragentList='kontragentList'/>
      </div> 

      <div>

        <h5>
         Товары
        </h5>
        
        
        <Button @click="addTemplateProduct" label="Добавить поступивший товар" icon="pi pi-plus" iconPos="left" /> 

        <br><br>        
        <DataTable :value="addedProductList" :scrollable="true" scrollHeight="200px">
          <Column header="Товар">           
            <template #body="slotProps">              
              <Dropdown  
              @change="OnChangeAddedProduct(slotProps.data)"
            v-model="slotProps.data.selectedProduct" 
            :optionValue="id"      
            :options="defaultProductList" 
            optionLabel="name" 
            placeholder="Выберите товар" 
            :filter="true"
            filterPlaceholder="Поиск"
            :class="{'p-invalid':slotProps.data.selectedProduct==null}"
            />
            
            </template>
          </Column>
          <Column header="Количество">
            <template #body="slotProps">
              <InputNumber :min="0" mode="decimal" v-model="slotProps.data.quantity" :class="{'p-invalid':(slotProps.data.quantity==0 || slotProps.data.quantity<0)}" />&nbsp;&nbsp;{{slotProps.data.unit}}
            </template>
          </Column>
          <Column header="Закупочная цена">
            <template #body="slotProps">  
              <InputNumber  :min="0" :minFractionDigits="2" :maxFractionDigits="5" mode="decimal"  v-model="slotProps.data.price" :class="{'p-invalid':(slotProps.data.price==0 || slotProps.data.price<0)}"/> 
            </template>
          </Column>
        </DataTable>
      </div>
      
      <div class="dd-bottom-button-wrap">    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <Button @click="saveAddedProduct" label="Сохранить поступление" class="mt-2" icon="pi pi-check"  />
      </div>
</template>


<script>
import axios from 'axios';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import KontragentSelector from './KontragentSelector.vue';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";

import ProductService from '../service/ProductService';
import KontragentService from '../service/KontragentService';
import BusinesEvent from '../service/BusinesEvent';

export default {
  name: 'AddProductPopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    Dropdown,
    Calendar,
    KontragentSelector,
    DataTable,
    Column,
    InputNumber
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {    
      addedProductList:[],
      headerProductList:null,
      
      selected_warehouse:null,
      defaultProductList:null,
      addedProductModel:{
                    date_create:null,
                    kontragent_id:null,
                  },
      kontragentList:null,  
      
    }
  },
  validations() {
        return {
          addedProductModel:{
            date_create:{ required },      
                 
          },
        }
    },
  created() {
      this.productService = new ProductService();
      this.kontragentService = new KontragentService();
      this.businesEvent = new BusinesEvent();
  },
  mounted() {

        this.selected_warehouse = this.dialogRef.data.selected_warehouse;      

        this.productService.getList(this.selected_warehouse.id).then(data => this.defaultProductList = data.list);
        this.kontragentService.getList().then(data => this.kontragentList = data);
        this.addTemplateProduct();
  
  },
  methods:{ 
    OnChangeAddedProduct(e){
      e.unit=e.selectedProduct.unit;
    },   
    addTemplateProduct(){

      this.addedProductList.push({
        selectedProduct:null,
        quantity:0,
        unit:null,
        price:0,
      });
    },

    OnKontragentUpdate(){
      this.kontragentService.getList().then(data => this.kontragentList = data);
    },
   
    closePopup(){
      this.dialogRef.close();
    },
    
    OnChangeKontragent(e){

        this.addedProductModel.kontragent_id=e.id
    },
    updateKontragentList(response) {     
      this.kontragentList=response.data; 
    },

   
    

  

    saveProduct(){
      this.productModel.warehouse_id=this.selected_warehouse.id;

      var warehouseQuantityMap={};
      for (const key in this.warehouseList) {
        var warehouseId=this.warehouseList[key].id;
        var quantity=this.quantityMap[key];
        warehouseQuantityMap[warehouseId]=quantity;
      } 

      var priceTypeMap={};
      for (const key in this.pricetypeList) {
        var priceTypeId=this.pricetypeList[key].id;
        var priceValue=this.priceMap[key];
        priceTypeMap[priceTypeId]=priceValue;
      } 
     
      axios({
          method: 'post',
          url: window.$backend_host+'/product/new',
          data: {
                'product':this.productModel,
                'stock':warehouseQuantityMap,
                'price':priceTypeMap,
          } ,
          headers: {
            "Content-type": "application/x-www-form-urlencoded"
          }
        })
        .then(this.productCreated);
    },

   
    productAdded(){
      
        this.dialogRef.close({
          'cmd':'addProduct'
        });
        
         
    },
    saveAddedProduct(){
     


      var productList={};
      for (const key in this.addedProductList) {
        var selectedProduct=this.addedProductList[key].selectedProduct;
        var quantity=this.addedProductList[key].quantity;
        var price=this.addedProductList[key].price;

        if(selectedProduct!=null && quantity>0){
          productList[selectedProduct.id]={
            quantity:quantity,
            price:price
          };
        }
      }

    

      if(Object.keys(productList).length>0 && this.v$.$invalid==false){

       /* var dateCreate=new Date(this.addedProductModel.date_create);
        console.log(dateCreate);
        dateCreate= new Date (dateCreate.toLocaleDateString('ru-RU', {timeZone: 'Europe/Moscow'}));
        console.log(dateCreate);*/

        var dateCreate=this.addedProductModel.date_create;

        this.businesEvent.kontragentAddedProducts({
          'data':{
            'date_create': dateCreate,
            'kontragent_id': this.addedProductModel.kontragent_id,
            'warehouse_id':this.selected_warehouse.id,
          },
          'product_list':productList
        }).then(this.productAdded);
        

        
      }

 
    },
    
    
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
</style>