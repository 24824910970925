<template>  
  <div class="">
    <Dropdown :class="{'p-invalid':v$.selectedKontragent.$invalid}" @change="OnKontragentSelect()" 
    :loading='isLoading' 
    :optionValue="id" 
    v-model="selectedKontragent" 
    :options="kontragentList" 
    optionLabel="fullname" 
    placeholder="Выберите контрагента" 
    :filter="true"
     filterPlaceholder="Поиск"/>
    <KontragentPopup  @on-kontragent-update="OnKontragentUpdate"/>
  </div>
 
</template>

<script>
//import axios from 'axios';
import Dropdown from 'primevue/dropdown';
import KontragentPopup from './KontragentPopup.vue';

import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";


export default {
  name: 'KontragentSelector',
  setup: () => ({ v$: useVuelidate() }),
  components: {    
    Dropdown,
    KontragentPopup
  }, 
  props: [
    'kontragentList'
  ],
  data() {
    return {
      selectedKontragent:null,
      isLoading:true,
       
      is_show_new_warehouse_popup:false,
    }
  },
  validations() {
        return {
            selectedKontragent:{ required }, 
        }
    },
  watch:{
    kontragentList(){
      this.isLoading=false;
      
    },

  },
  
  methods:{
    OnKontragentUpdate:function(){
      this.isLoading=true;

     
       
        this.$emit('on-kontragent-update',{
         severity:'success',
         summary: 'Склады', 
         detail:'Склады успешно обновлены'
       });  

      },

    handleClick:function(){
       
       this.is_show_new_warehouse_popup=true;
    },
    OnKontragentSelect: function() {
        this.$emit('change',this.selectedKontragent);    
     },
     updateWarehousesList:function(response){
       
        this.warehouserList=response.data;       
    },
    
  },
}
</script>


