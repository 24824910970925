import axios from 'axios';
export default class CategoryService {

   

	getList() {
      
        return axios
                    .get(window.$backend_host+'/category/list').then(d => d.data);            
    }

    createCategory(params){


        if(params.parent_category_id!=null){
            params.parent_category_id=params.parent_category_id.id;
        }

        return axios({
            method: 'post',
            url: window.$backend_host+'/category/new',
            data: {
                category:params
            } ,
            headers: {
            "Content-type": "application/x-www-form-urlencoded"
            }
        }); 
    }
}