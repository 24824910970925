<template>  
  <div class="user-panel-wrap">
    <div class="user-panel-body" v-if="currentUser==null" >
      <Skeleton width="10rem" class="mb-2 name-label" ></Skeleton>
      <Skeleton shape="circle" size="4rem" class="mr-2 avatar-icon"></Skeleton>
    </div>
    <div class="user-panel-body" v-if="currentUser!=null" >
     
      <div class="user-panel-labels">
        <div class="name-label">
          {{currentUser.firstname}}
        </div>
        <div>
          <Button @click="logoutButtonHandler" label='Выход' icon="pi pi-sign-out" class="p-button-rounded p-button-secondary p-button-text" />
        </div>
      </div>

    </div>
  </div>
 
</template>

<script>

import Skeleton from 'primevue/skeleton';
import Button from 'primevue/button';

//import AuthService from './service/AuthService';

export default {
  name: 'UserPanel',

  components: {     
    Skeleton,
    Button
  }, 
  props: [
    'currentUser'
  ],
  data() {
    return {
     
    }
  },
  created() {      
     // this.authService = new AuthService();
  },
  beforeMount(){
   
  },
  methods:{
    logoutButtonHandler(){
      this.$root.UserLogout();
    },
  },
}
</script>

<style>
  .name-label {
      padding: 5px;
  }
  .avatar-icon{
    margin-right: 10px;
  }
  .user-panel-body{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .user-panel-labels{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
  }
  
</style>
