<template>  
  <div v-if="isGuest" >
    <LoginPage  @on-user-login="OnUserLogin"/>
  </div>

  <div v-if="!isGuest" >
    <MainPage :currentUser='currentUser'/>
  </div>
  
  <Toast  position="bottom-right" />
</template>
<script>
import axios from 'axios';
import Toast from 'primevue/toast';


import MainPage from './components/MainPage.vue';
import LoginPage from './components/LoginPage.vue';

import AuthService from './service/AuthService';
import UserService from './service/UserService';

export default {
  name: 'App',
  components: {    
    Toast,
    MainPage,
    LoginPage
  },
  
  data() {
        return {
          isGuest:true,
          currentUser:null,
        }
  },
  created() {      

    

    //глобальный перехватчих ошибок запросов апи
    axios.interceptors.response.use(
        response => response,
        this.errorResponseHandler
    );

    this.authService = new AuthService();
    this.userService = new UserService();

    var authUser=this.authService.getSaveUserData();
    if(authUser!=false){
      this.isGuest=false;
      this.authService.setAccessToken(authUser.access_token);   
      this.userService.geUserInfo().then(this.OnUserContinue,this.OnUserInvalidToken);
    }


     
  },
  methods:{

    /**
     * Обработчик ошибок запросов апи
     * @param {Error} data 
     */
    errorResponseHandler(data) {
      if(data.response.status==403){
            this.$toast.add({severity:'error', summary: 'Ошибка входа', detail:'Выполните повторный вход в систему', life: 3000});
            this.UserLogout();
      }

      
      if(data.response.status==500){
            this.$toast.add({severity:'error', summary: 'Произошла ошибка, попробуйте ещё раз', detail:'', life: 3000});         
      }
    },

    OnUserContinue(userData){
      if(userData!=false){        
        this.currentUser=userData.data;        
      }
    },

    OnUserLogin(userData){      
      if(userData!=false){
        this.isGuest=false;
        this.authService.saveAccessToken(userData.access_token);   
        this.authService.setAccessToken(userData.access_token);  
        this.currentUser=userData;
      }
    },

    UserLogout(){   
      this.isGuest=true;
      this.authService.clearAccessToken();  
      this.currentUser=null;
    },
  },
}
</script>

<style scoped>
  
</style>
