import axios from 'axios';
import VueCookies from 'vue-cookies'

export default class AuthService {

    setAccessToken(access_token) {	           
        axios.defaults.headers.common['x-auth-token'] = access_token;       
    }

    saveAccessToken(access_token) {	 
        VueCookies.set("auth_token",access_token,60 * 60 * 24 * 30);
    }
    
    getSaveUserData(){
        var accessToken= VueCookies.get("auth_token");
        if(accessToken){
            return {
                'access_token':accessToken,
            }
        }else{
            return false;
        }
    }

    clearAccessToken(){
        VueCookies.remove("auth_token");
        delete axios.defaults.headers.common['x-auth-token'];    
    }
}