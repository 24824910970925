<template>   
      <div> 
        <h5>
          Период
        </h5>
      <Calendar  
      dateFormat="dd.mm.yy"
       inputId="range" 
       :class="{'p-invalid':v$.period_dates.$invalid}" 
       v-model="period_dates" selectionMode="range" :manualInput="false" />
      
      <Button @click='doReport' label="Показать отчёт" icon="pi pi-plus" iconPos="left" /> 

      </div> 


      <div class="report-block" >
        <div class="rb-loading" v-if="reportIsMakining==true">
          <ProgressSpinner />
        </div>
        <div v-if="reportIsShow==true">
            <div>
             
              <div class="rb-value-item">
                      <b>
                        Сумма продаж: 
                      </b>
                      {{reportData.selling_summ}} руб.
              </div>

              <div class="rb-value-item">
                      <b>
                      Себестоимость проданного товара: 
                      </b>
                      {{reportData.self_cost}}  руб.
              </div>

              <div class="rb-value-item">
                      <b>
                      Продажи по менеджерам: 
                      </b>
                      <br><br>
                      <DataTable :resizableColumns="true" columnResizeMode="fit" :value="reportData.selling_by_managers" :scrollable="true" scrollHeight="200px">
                        <Column header="#" field="id" class="pr-saled-product-column-id"> 
                        </Column>
                        <Column header="Сотрудник" field="name" class="pr-saled-product-column-name"> 
                        </Column>
                        <Column header="Сумма" field="summ" class="pr-saled-product-column-name"> 
                          <template #body="slotProps">  
                            {{slotProps.data.selling_summ}} руб.
                          </template>         
                        </Column>
                      </DataTable>
              </div>

              <div class="rb-value-item">
                      <b>
                      Список проданного товара: 
                      </b>
                      <br><br>
                      <DataTable :resizableColumns="true" columnResizeMode="fit" :value="reportData.selling_product_list" :scrollable="true" scrollHeight="200px">
                        <Column header="#" field="id" class="pr-saled-product-column-id"> 
                        </Column>
                        <Column header="Название" field="name" class="pr-saled-product-column-name"> 
                        </Column>
                      </DataTable>
              </div>
            </div>
        </div>
      </div>
      

    
      <div class="dd-bottom-button-wrap">    
        <Button label="Закрыть" icon="pi pi-times" @click="closePopup" class="p-button-text" />  
      </div>
</template>
 
<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";
import ProgressSpinner from 'primevue/progressspinner';



import ReportService from '../service/ReportService';


export default {
  name: 'PeriodReportPopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    DataTable,
    Column,
    Calendar,
    ProgressSpinner 
  }, 
  inject: ['dialogRef'],
  props: [
    
  ],
  data() {
    return {    
      period_dates:null,
      reportIsMakining:null,
      reportIsShow:null,
      reportData:{
        selling_summ:null,
        self_cost:null,
        selling_product_list:null,
        selling_by_managers:null,
      },
      selected_warehouse:null,
    }
  },
  validations() {
        return {
          period_dates:{
            required
          }
        }
    },
  created() {
      this.reportService = new ReportService();
  },
  mounted() {
    this.selected_warehouse = this.dialogRef.data.selected_warehouse; 
   

  },
  methods:{   
   
    closePopup(){
      this.dialogRef.close();
    },

    doReport(){

      if(this.v$.$invalid==false){
        this.reportIsMakining=true;
        this.reportIsShow=false;
        var self=this;
        this.reportService.getByDates(this.selected_warehouse,this.period_dates).then(function(data){
            self.reportData=data;
            self.reportIsMakining=false;
            self.reportIsShow=true;
        });
      }

      
     
    },

    
    
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
  .report-block{
   margin-top: 20px;
  }

  .rb-loading{
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
  }
  .rb-value-item{
    margin-bottom: 10px;;
  }

  th.pr-saled-product-column-id,
  td.pr-saled-product-column-id{
    width: 20%;
  }
  th.pr-saled-product-column-name,
  td.pr-saled-product-column-name{
    width: 80%;
  }
</style>