import axios from 'axios';
export default class ProductService {

    getPrintFile(warehouseId){   
        
        return axios
            .get(window.$backend_host+'/product/print-product-list',{
                params:{
                'warehouse_id':warehouseId
                }              
            }).then(d => d.data);
    }

    createProduct(params){
        return axios({
            method: 'post',
            url: window.$backend_host+'/product/new',
            data: params ,
            headers: {
            "Content-type": "application/x-www-form-urlencoded"
            }
        }); 
    }

	getList(warehouseId) {
		
        return axios
                    .get(window.$backend_host+'/product/list',{
                        params:{
                        'warehouse_id':warehouseId
                        }              
                    }).then(d => d.data);        
    }

    updateProduct(){
       
    }
    
}