<template>   
      <div> 
        <Button @click='addNewCategory' label="Добавить категорию" icon="pi pi-plus" iconPos="left" /> 

      </div> 

     

      <div>

        <h5>
         Список категорий
        </h5>
               
        <DataTable :value="categoryList" :scrollable="true" scrollHeight="200px">
          <Column header="Название" field="name"> 
          </Column>
        </DataTable>
      </div>

    
      <div class="dd-bottom-button-wrap">    
        <Button label="Закрыть" icon="pi pi-times" @click="closePopup" class="p-button-text" />  
      </div>
</template>

<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useVuelidate } from "@vuelidate/core";
//import {required } from "@vuelidate/validators";

import CategoryFormPopup from './CategoryFormPopup.vue';

import CategoryService from '../service/CategoryService';


export default {
  name: 'CategoryPopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    DataTable,
    Column,
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {    
      categoryList:null,
      isCategoryUpdated:null,
    }
  },
  validations() {
        return {
          
        }
    },
  created() {
      this.categoryService = new CategoryService();
  },
  mounted() {

    this.categoryService.getList().then(this.showCategoryList);

    

  },
  methods:{   
   
    closePopup(){
      this.dialogRef.close();
    },

    showCategoryList(data){
     
     this.categoryList = data
    },

    addNewCategory(){     
        var self=this;
        
         this.$dialog.open(CategoryFormPopup, {
                props: {
                    header: 'Создание категории',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                             
                },
               
                onClose(options) {

                  if(
                    options['data']!=undefined &&
                  options.data!=false && 
                  options.data['cmd']!=undefined &&
                    options.data.cmd=='addCategory'){     

                      self.categoryService.getList().then(self.showCategoryList);
                   //   self.isCategoryUpdated=true;
                     
                  }
                }
               
             
        });
      },
    
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
</style>