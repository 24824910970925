import axios from 'axios';
export default class WarehouseService {

   

	getList() {
      
        return axios
                    .get(window.$backend_host+'/warehouse/list').then(d => d.data);            
    }

    
}