<template>   
      <div> 
        <h5>
          Название
        </h5>
        <InputText :class="{'p-invalid':v$.newCategoryModel.name.$invalid}" v-model="newCategoryModel.name"  type="text"  />        
      </div> 
      <div> 
        <h5>
          Родительская категория
        </h5>
        <Dropdown v-model="newCategoryModel.parent_category_id" :modelValue="id" :options="categoryList" optionLabel="name" placeholder="" />
      </div> 
    
      <div class="dd-bottom-button-wrap">    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <Button @click="saveAddedCategory" label="Сохранить категорию" class="mt-2" icon="pi pi-check"  />
      </div>
</template>

<script>

import Button from 'primevue/button';


import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';


import { useVuelidate } from "@vuelidate/core";
import {required } from "@vuelidate/validators";

import CategoryService from '../service/CategoryService';

//newCategoryModel.name
export default {
  name: 'CategoryFormPopup',
  setup: () => ({ v$: useVuelidate() }),
  components: {  
    Button,
    Dropdown,
    InputText,
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {    
      categoryList:null,
      newCategoryModel:{
        name:null,
        parent_category_id:null
      },
    }
  },
  validations() {
        return {
          newCategoryModel:{
            name:{ required },      
                 
          },
        }
    },
  created() {
      this.categoryService = new CategoryService();
  },
  mounted() {

    this.categoryService.getList().then(data => this.categoryList = data);

    

  },
  methods:{   
   
    closePopup(){
      this.dialogRef.close();
    },

    saveAddedCategory(){
      if(this.v$.$invalid==false){
        var self=this;


        this.categoryService.createCategory(this.newCategoryModel).then(function(){
          self.dialogRef.close({'cmd':'addCategory'});
        });
      }
    }
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
</style>