<template>  
  <div class="">
    <h1>Список товаров</h1>   

   
    
      <DataTable :value="list" responsiveLayout="scroll">
            <Column v-for="col of header" :field="col.field" :header="col.header" :key="col.field"> 
              <template  #body="slotProps" v-if="col.field=='self_cost' || isPriceField(col.field) || col.field=='category_id'">  
                <InputNumber :min="0" v-if="isPriceField(col.field)" :minFractionDigits="2" :maxFractionDigits="5" mode="decimal" v-model="slotProps.data[col.field]"/>                
                <Dropdown v-if="col.field=='category_id' && currentUser.group_settings.change_category_product==true"  v-model="slotProps.data[col.field]" :modelValue="id" optionValue="id" :options="categoryList" optionLabel="name" placeholder="" />
                <InputNumber :min="0" v-if="col.field=='self_cost' && currentUser.group_settings.self_cost_edit_enabled==true" :minFractionDigits="2" :maxFractionDigits="5" mode="decimal" v-model="slotProps.data[col.field]"/>  
              </template>
            </Column>
            
            <Column  v-if="isShowSaveButton" > 
              <template #body="slotProps">  
                <Button  icon="pi pi-save" @click="saveProduct(slotProps.data)" />
              </template>           
            </Column>           
      </DataTable>
  </div>
</template>

<script>


import axios from 'axios';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

import CategoryService from '../service/CategoryService';

export default {
  name: 'ProductList',
  components: {    
    DataTable,
    Column,
    InputNumber,
    Button ,
    Dropdown
  }, 
  props: [
    'selected_warehouse',
    'currentUser'
],
  watch: {
    selected_warehouse() {
        this.getProducts()
      }
   },
  data() {
    return {
        list:[],
        header:[],   
        priceMap:[],  
        isShowSaveButton:false,   
        categoryList:null,
    }
  },
  created() {
      this.categoryService = new CategoryService();
  },
  mounted() {
    this.updateCategoryList();
  },
  methods:{
      updateCategoryList:function(){
       
        this.categoryService.getList().then(data => this.categoryList = data);
      },

      updateProductList:function(response){

        this.list=response.data.list;
        this.header=response.data.header;


        var isShowPrice=false;
        for (const key in this.header) {
          if (this.isPriceField(this.header[key].field)) {
            isShowPrice=true;
          }
        }
        this.isShowSaveButton=isShowPrice;

        this.$toast.add({severity:'info', summary: 'Список товаров обновлён', life: 1000});
      },
     getProducts: function() {

      this.$toast.add({severity:'info', summary: 'Обновление списка товаров...', life: 1000});
          axios
            .get(window.$backend_host+'/product/list',{
              params:{
                'warehouse_id':this.selected_warehouse.id
              }              
            })
                  .then(this.updateProductList);                 
          
     },
     isPriceField(field){
      return field.search('price')!==-1;
     },
     saveProduct(productData){
      
     
      var priceFieldList=[];
      for (const key in this.header) {
        if (this.isPriceField(this.header[key].field)) {
        
          priceFieldList.push(this.header[key].field);
        }
      }

      

      var priceValieMap={};
      //var categoryValueMap={};


      for (const key in priceFieldList) {
          priceValieMap[priceFieldList[key]]=productData[priceFieldList[key]];
        //  categoryValueMap[priceFieldList[key]]=productData[priceFieldList[key]];
      }
  

      var params={
                'price':priceValieMap,
                'category_id':productData.category_id,
      };

      if(this.currentUser.group_settings.self_cost_edit_enabled==true){
        params['self_cost']=productData.self_cost;
      }

 
      axios({
          method: 'post',
          url: window.$backend_host+'/product/edit',
          params:{
            id:productData.id,
            warehouse_id:this.selected_warehouse.id
          },
          data: params ,
          headers: {
            "Content-type": "application/x-www-form-urlencoded"
          }
        })
        .then(this.productCreated);

        this.$emit('on-show-toast',{
          severity:'success',
          summary: 'Товар', 
          detail:'Товар успешно изменён'
        });  
     },
  },
}
</script>


<style>
  #app .p-datatable .p-datatable-tbody>tr>td{
    padding: 0px 5px;
  }
  #app table .p-inputtext{
    padding: 2px 0.5rem;
  }
  #app .p-datatable .p-datatable-tbody>tr:hover td {
    background: #f8f9fa;
  }

 
</style>