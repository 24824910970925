<template>
      <div>
        <DataTable :value="kontragentList" :scrollable="true" scrollHeight="500px">
         
          <Column field="date_create" header="Дата добавления" >
          </Column>
          <Column field="fullname" header="Ф.И.О" > 
          </Column>
          <Column field="email" header="Email" >
          </Column>
          <Column field="phone" header="Телефон" >
          </Column>
          <Column  header="Взаиморасчёты" >
            <template #body>
              <Button label="Просмотреть"/> 
            </template>     
          </Column>
          <Column header="Документы" >
            <template #body>
              <Button label="Просмотреть"/> 
            </template>             
          </Column>
        </DataTable>
      </div>
      
      <div class="dd-bottom-button-wrap">    
        <Button label="Отмена" icon="pi pi-times" @click="closePopup" class="p-button-text" />      
        <!--
          <Button @click="createNewKontragent" label="Добавить нового контрагента" class="mt-2" icon="pi pi-plus-circle"  />
      -->
        </div>
</template>

 
<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import KontragentCreatePopup from './KontragentCreatePopup.vue';

import KontragentService from '../service/KontragentService';


export default {
  name: 'KontragentListPopup',
  components: { 
    DataTable,
    Column,  
    Button
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {   
      kontragentList:null,  
    }
  },
 
  created() {
      this.kontragentService = new KontragentService();
  },
  mounted() {
        this.kontragentService.getList().then(data => this.kontragentList = data);
  },
  methods:{    
    closePopup(){
      this.dialogRef.close();
    },
    createNewKontragent(){
      this.$dialog.open(KontragentCreatePopup, {
                props: {
                    header: 'Создание нового контрагента',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                           
                },
               
                onClose() {
                
                }
               
             
        });
    },
  },
}
</script>



<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }
</style>