<template>  
    
    <UserPanel :currentUser='currentUser'/>

    <div class="top-menu">
        <div>
          <WarehouseSelector 
            @on-warehouse-selected="OnWarehouseSelect"
            @on-show-toast="OnShowToast"
            :currentUser='currentUser'
          />
        </div>

        <div v-if="currentUser.group_settings.create_product_enabled==true" >
          <Button @click="showNewProductPopup" label="Создать товар" icon="pi pi-plus" iconPos="left" />        
        </div>

        <div>
          <Button @click="showEventListPopup" label="Документы" icon="pi pi-file" iconPos="left" /> 
        </div>

        <div>
          <Button @click="printProductList" label="Печать прайса" icon="pi pi-print" iconPos="left" />              
        </div>
      
        <div v-if="currentUser.group_settings.period_report==true" >
          <Button @click="showPeriodReportPopup" label="Отчёт за период" icon="pi pi-plus" iconPos="left" />        
        </div>

        <div>
          <Button @click="showKontragentListPopup" label="Список контрагентов"  icon="pi pi-shopping-cart" iconPos="left" />        
        </div>

        <div>
          <Button @click="showCategoryListPopup" label="Категории"  icon="pi pi-shopping-cart" iconPos="left" />        
        </div>

        <div>          
          <Button @click="showAddProductPopup" label="Поступление товара" icon="pi pi-plus" iconPos="left" /> 
          
          <Button v-if="currentUser.group_settings.add_product_enabled==true && (currentUser==null || currentUser.group_settings.sale_button_is_name_by_admin==false)"  @click="showSaleProductPopup" label="Продажа товар" icon="pi pi-minus" iconPos="left" /> 
          <Button v-if="currentUser.group_settings.add_product_enabled==true && (currentUser!=false && currentUser.group_settings.sale_button_is_name_by_admin==true)"  @click="showSaleProductPopup" label="Списание товара" icon="pi pi-minus" iconPos="left" /> 
        </div>
    </div>

    <div> 
      <ProductList 
      @on-show-toast="OnShowToast" 
      ref="main_product_list" 
      v-bind:selected_warehouse='selected_warehouse'
      :currentUser='currentUser'
      />
    </div>


    
   
    <DynamicDialog 
          @on-productlist-update="OnProductListUpdate"
          @on-show-toast="OnShowToast"
    />
    
</template>
<script>

import axios from 'axios';

import ProductList from './ProductList.vue';
import WarehouseSelector from './WarehouseSelector.vue';
import Button from 'primevue/button';

import AddProductPopup from './AddProductPopup.vue';
import NewPropuctPopup from './NewProductPopup.vue';


import SaleProductPopup from './SaleProductPopup.vue';
import EventListPopup from './EventListPopup.vue';

import CategoryPopup from './CategoryPopup.vue';
import PeriodReportPopup from './PeriodReportPopup.vue';




//import PriceTypePopup from './PriceTypePopup.vue';
import DynamicDialog from 'primevue/dynamicdialog';
import KontragentListPopup from './KontragentListPopup.vue';
import UserPanel from './UserPanel.vue';
import ProductService from '../service/ProductService';


export default {
  name: 'MainPage',
  components: {
    ProductList,
    WarehouseSelector,
    Button,
  //  PriceTypePopup,
    DynamicDialog,
    UserPanel,
},
  props:[
    'currentUser'
  ],
  created() {      
      this.productService = new ProductService();
    },
  watch: {
    currentUser() {
       
      }
   },
  data() {
        return {
          selected_warehouse:false,       
          is_show_add_product_popup:false,
          is_show_sale_product_popup:false,          
        }
  },
  methods:{
      printProductList(){

        if(this.selected_warehouse!=false){

         /* this.productService.getPrintFile(this.selected_warehouse.id)
              .then(response => {
                console.log(response)
            
                let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }),
                    url = window.URL.createObjectURL(blob)
            
                window.open(url) 
                })*/




          axios
            .get(window.$backend_host+'/product/print-list',{
              params:{
                'warehouse_id':this.selected_warehouse.id
              }              
            }).then(function(response){
                      
              

              var tableHTML=response.data.table
              
              let w = window.open()
              w.document.write(tableHTML)
              w.document.close()
              w.setTimeout(function () {
                w.print()
              }, 1000);

            });      
          
        }

      },

      OnWarehouseSelect(warehouse_data){
          this.selected_warehouse=warehouse_data;
      },
      OnShowToast(e){       
       
        this.$toast.add({severity:e.severity, summary: e.summary, detail:e.detail, life: 3000});
      },
     
      
     
      OnProductListUpdate(){
        this.$refs.main_product_list.getProducts();
      },

      
      showPeriodReportPopup(){     
        var _this=this;
        
         this.$dialog.open(PeriodReportPopup, {
                props: {
                    header: 'Отчёт за период',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                  selected_warehouse:this.selected_warehouse    
                },
               
                onClose(options) {

                  


                 if(
                  options['data']!=undefined &&
                 options.data!=false && 
                 options.data['cmd']!=undefined &&
                  options.data.cmd=='newProduct'){


                  _this.OnShowToast({
                      severity:'success',
                      summary: 'Товар', 
                      detail:'Товар успешно создан'
                    });  

                    //обновлять только этот товар?
                    _this.OnProductListUpdate();
                 }
                }
               
             
        });
      },

      showCategoryListPopup(){     
        var _this=this;
        
         this.$dialog.open(CategoryPopup, {
                props: {
                    header: 'Категории',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                             
                },
               
                onClose() {              
                  _this.$refs.main_product_list.updateCategoryList();
                }
               
             
        });
      },

      showNewProductPopup(){     
        var _this=this;
        
         this.$dialog.open(NewPropuctPopup, {
                props: {
                    header: 'Создание товара',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                  selected_warehouse:this.selected_warehouse,            
                },
               
                onClose(options) {

                  


                 if(
                  options['data']!=undefined &&
                 options.data!=false && 
                 options.data['cmd']!=undefined &&
                  options.data.cmd=='newProduct'){


                  _this.OnShowToast({
                      severity:'success',
                      summary: 'Товар', 
                      detail:'Товар успешно создан'
                    });  

                    //обновлять только этот товар?
                    _this.OnProductListUpdate();
                 }
                }
               
             
        });
      },


      showAddProductPopup(){     
        var _this=this;
        
         this.$dialog.open(AddProductPopup, {
                props: {
                    header: 'Поступление товара',
                    style: {
                    
                    },                   
                    modal: true
                },
                data:{
                  selected_warehouse:this.selected_warehouse,            
                },
               
                onClose(options) {

                  


                 if(
                  options['data']!=undefined &&
                 options.data!=false && 
                 options.data['cmd']!=undefined &&
                  options.data.cmd=='addProduct'){


                  _this.OnShowToast({
                      severity:'success',
                      summary: 'Товар', 
                      detail:'Товар успешно добавлен'
                    });  

                    //обновлять только этот товар?
                    _this.OnProductListUpdate();
                 }
                }
               
             
        });
      },

      showSaleProductPopup(){     
        var _this=this;
        
         this.$dialog.open(SaleProductPopup, {
                props: {
                    header: 'Продажа товара',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                  selected_warehouse:this.selected_warehouse,            
                },
               
                onClose(options) {

                  


                 if(
                  options['data']!=undefined &&
                 options.data!=false && 
                 options.data['cmd']!=undefined &&
                  options.data.cmd=='saleProduct'){


                  _this.OnShowToast({
                      severity:'success',
                      summary: 'Товар', 
                      detail:'Товар успешно продан'
                    });  

                    //обновлять только этот товар?
                    _this.OnProductListUpdate();
                 }
                }
               
             
        });
      },
      showEventListPopup(){     
        
        
         this.$dialog.open(EventListPopup, {
                props: {
                    header: 'Список документов',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                           
                },
               
                onClose() {
                
                }
               
             
        });
      },

      showKontragentListPopup(){
        this.$dialog.open(KontragentListPopup, {
                props: {
                    header: 'Список контрагентов',
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true
                },
                data:{
                           
                },
               
                onClose() {
                
                }
               
             
        });
      },
  },
}
</script>

<style scoped>
  .top-menu{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }

 
</style>
