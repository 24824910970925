<template>   

      <div>
        <h5>
          Контрагент
        </h5>
       
        ФИО: <b>{{eventData.kontragent.firstname}} {{eventData.kontragent.surename}} {{eventData.kontragent.patronimic}}</b>
        <br>
        Email: <b>{{eventData.kontragent.email}}</b>
        <br>
        Адрес: <b>{{eventData.kontragent.address}}</b>
        <br>
        Индекс:<b> {{eventData.kontragent.zip}}</b>
        <br>
        Телефон: <b>{{eventData.kontragent.phone}}</b>
        <br>
        ИНН: <b>{{eventData.kontragent.inn}}</b>
        <br>
        КПП: <b>{{eventData.kontragent.kpp}}</b>
        <br>
        Расчётный счёт:<b>{{eventData.kontragent.rs}}</b>
        <br>
        ОГРН: <b>{{eventData.kontragent.ogrn}}</b>
        <br>
        БИК: <b>{{eventData.kontragent.bik}}</b>
        <br>
        Описание: <br>{{eventData.kontragent.description}}        
      </div>
      <br>
      <div>
        <DataTable   responsiveLayout="scroll" :value="eventData.product_table" :scrollable="true" scrollHeight="500px">
         <Column  field="name" header="Наименование"> 
          </Column>
          <Column  field="price" header="Стоимость"> 
          </Column>
          <Column  field="last_quantity" header="Предидущие остатки"> 
          </Column>
          <Column  field="new_quantity" header="Новые остатки"> 
          </Column>
        </DataTable>
      </div>
      
</template>


<script>


import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import BusinesEvent from '../service/BusinesEvent';

export default {
  name: 'EventViewPopup',

  components: {  
    DataTable,
    Column,
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {          
      eventData:{
        kontragent:{
          firstname:"",
          surename:"",
          patronimic:"",
          email:'',
          address:"",
          zip:'',
          phone:'',
          inn:'',
          kpp:'',
          rs:'',
          ogrn:'',
          bik:'',
          description:''
        },
        product_table:null
      }       
    }
  },
  created() {      
      this.businesEvent = new BusinesEvent();
  },
  mounted() {
        const params = this.dialogRef.data; 
        this.businesEvent.getViewData(params.event_id).then(data => this.eventData = data);
       
  },
  methods:{ 
    
    
  },
}
</script>

<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rowClass{
  width: 10px;
}
</style>

