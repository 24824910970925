import axios from 'axios';
export default class UserService {

    
    geUserInfo() {		       
        return axios
                    .get(window.$backend_host+'/user/get-info',{
                              
                    }).then(d => d.data);        
    }
    login(login,password) {		       
        return axios({
                        method: 'post',
                        url: window.$backend_host+'/user/login',
                        data: {
                            'user':{     
                                login:login,
                                password:password,                  
                            }  
                        },
                        headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                        }
                    }).then(d => d.data);  
    }
    
}