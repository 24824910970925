import { createApp } from 'vue'
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import VueCookies from 'vue-cookies'

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";



window.$backend_host = 'https://stroyrinok.kutuy.com' ;



const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);
app.use(DialogService);
app.use(VueCookies);

app.mount("#app");



