<template>        
      <div>
 
        <DataTable   responsiveLayout="scroll" :value="eventList" :scrollable="true" scrollHeight="500px">
         
          <Column  field="id" header="#" > 
          </Column>
          <Column field="date" header="Дата создания"> 
          </Column>
          <Column field="name" header="Наименование"  > 
          
            <template #body="slotProps">  
              {{slotProps.data.name}}
              <Button @click="viewEvent(slotProps.data)" icon="pi pi-eye"/> 
            </template>
          </Column>
        </DataTable>
      </div>
      
      
</template>


<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import EventViewPopup from './EventViewPopup';
import BusinesEvent from '../service/BusinesEvent';

export default {
  name: 'EventListPopup',

  components: {  
    Button,
    DataTable,
    Column
   
  }, 
  inject: ['dialogRef'],
  props:[
   
  ],
  data() {
    return {          
      eventList:null,        
    }
  },
  created() {      
      this.businesEvent = new BusinesEvent();
  },
  mounted() {
        this.businesEvent.getList().then(data => this.eventList = data);
  },
  methods:{ 
    
    viewEvent(eventData){

      
       this.$dialog.open(EventViewPopup, {
                props: {
                    header: 'Просмотр документа - '+eventData.name,
                    style: {
                        width: '50vw',
                    },
                    breakpoints:{
                        '960px': '75vw',
                        '640px': '90vw'
                    },
                    modal: true,
                }, 
                data:{
                      event_id:eventData.id
                },               
            });
    }
  },
}
</script>

<style scoped>  
  .dd-bottom-button-wrap{
    margin-top: 20px;
    text-align: right;
  }

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rowClass{
  width: 10px;
}
</style>

