import axios from 'axios';
export default class KontragentService {

   

	getList(warehouseId) {
		
        return axios
                    .get(window.$backend_host+'/kontragent/list',{
                        params:{
                        'warehouse_id':warehouseId
                        }              
                    }).then(d => d.data);            
    }

    
}