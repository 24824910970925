<template>  
  <div class="">
    <Dropdown @change="OnWarehouseSelect()" v-model="selectedWarehouse" :modelValue="id" :options="warehouserList" optionLabel="name" placeholder="Выберите склад" />
    <WarehousePopup v-if="currentUser.group_settings.create_warehouse_enabled==true" @on-warehouse-update="OnWarehouseUpdate"/>
  </div>
 
</template>

<script>
import axios from 'axios';
import Dropdown from 'primevue/dropdown';

import WarehousePopup from './WarehousePopup.vue';


export default {
  name: 'WarehouseSelector',

  components: {    
    Dropdown,
    WarehousePopup
  }, 
  props:[
    'currentUser'
  ],
  data() {
    return {
      selectedWarehouse:null,
      warehouserList:[],  
      is_show_new_warehouse_popup:false,
    }
  },
  beforeMount(){
    this.getWarehouses()
  },
  methods:{
    //событие изменение складов    
    OnWarehouseUpdate:function(){
       
      //обновляю список складов в селекторе
      this.getWarehouses();
      //отправля родителю сообытие для формирвоания тостов
      this.$emit('on-show-toast',{
        severity:'success',
        summary: 'Склады', 
        detail:'Склады успешно обновлены'
      });  
    },

    handleClick:function(){
       
       this.is_show_new_warehouse_popup=true;
    },
    OnWarehouseSelect: function() {
       
        this.$emit('on-warehouse-selected',this.selectedWarehouse);          
          
     },
     updateWarehousesList:function(response){
       
        this.warehouserList=response.data;    
        if(this.warehouserList!=undefined && this.warehouserList.length>0){
          this.selectedWarehouse= this.warehouserList[0];
          this.OnWarehouseSelect();
        }
        

      },
     getWarehouses: function() {


          axios
            .get(window.$backend_host+'/warehouse/list')
              .then(this.updateWarehousesList);                 
          
     }
  },
}
</script>


